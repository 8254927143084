// ignore-string-externalization
import { transport } from '../transport';
import { BrowserResolver } from '@spotify-internal/remote-config-resolver-browser';
// Properties need to be defined before below function is called or else no properties will be used to calculate the propertySetId
// @ts-ignore
import * as properties from './properties';
import { getAccessToken } from '../auth/accessTokenManager';

export function getConfiguration() {
  // https://backstage.spotify.net/docs/default/component/remote-configuration-js-sdk/usage/resolving/#using-browserresolver
  const resolver = new BrowserResolver({
    clientId: 'artists-spotify-com',
    transport,
    timeout: 20000,
    // only applies when there isnt anything in the cache
    // potential place where app could slow down
  });

  return getAccessToken().then(([token]) => {
    if (token) {
      // If a logged-in user is present, fetch the username and use to resolve remote config
      return transport
        .request('https://api.spotify.com/v1/me', {
          responseType: 'json',
          authorize: true,
        })
        .then(({ body: { id: userKey } }) =>
          resolver.resolve({ username: userKey }),
        );
    }
    // If no logged-in user, use unauthenticated remote config resolution
    // https://backstage.spotify.net/docs/default/component/remote-configuration-js-sdk/usage/unauth/
    return resolver.resolve({ username: null });
  });
}
