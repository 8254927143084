// ignore-string-externalization

/* eslint no-extend-native: ["error", { "exceptions": ["Function"] }] */

if (
  typeof window !== 'undefined' &&
  window.navigator &&
  window.navigator.userAgent &&
  /Edge\/1[0-4]\./.test(window.navigator.userAgent)
) {
  // Fix for bug in Microsoft Edge: https://github.com/Microsoft/ChakraCore/issues/1415#issuecomment-287888807
  Function.prototype.call = function call(t, ...args) {
    return this.apply(t, Array.prototype.slice.apply(args, [0]));
  };
}

export {};
