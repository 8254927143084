// ignore-string-externalization
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

/**
 * Usage:
 *
 * const withFooAndBar = withHooks<{
 *   foo: string,
 *   doBar: () => void
 * }, {
 *   bing: string
 * }>(() => {
 *   return {
 *     foo: 'Hello',
 *     doBar: () => alert('To the bar!')
 *   }
 * });
 *
 * const FooBarBingComponent = ({foo, doBar, bing}: {
 *   foo: string,
 *   doBar: () => void,
 *   bing: string
 * }) => <button onClick={doBar}>{foo}, {bing}</button>;
 *
 * const FooBarBing = withFooAndBar(FooBarBingComponent);
 *
 * <FooBarBing bing="World" />
 *
 */

export function withHooks<PropsFromHooks extends object>(
  mapHooksToProps: (props: unknown) => PropsFromHooks,
  displayName = 'Hooks',
) {
  return <P extends PropsFromHooks>(C: React.ComponentType<P>) => {
    function Hooks(props: Omit<P, keyof PropsFromHooks>) {
      return (
        <C //
          {...(props as P)} // as P cast needed, unfortunately :( See https://github.com/microsoft/TypeScript/issues/35858#issuecomment-573909154
          {...mapHooksToProps(props)}
        />
      );
    }

    Hooks.displayName = `${displayName}(${
      C.displayName || C.name || 'Component'
    })`;

    return hoistNonReactStatics(Hooks, C);
  };
}
