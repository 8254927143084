import React from 'react';
import { S4XLanguageSelectDialogWrapper } from './S4XLanguageSelectDialogWrapper';
import { useLanguageSelectionContext } from './index';

export function LanguageSelectionDialogWrapper() {
  const { isOpen, dispatch } = useLanguageSelectionContext();

  const dialogComponent = (
    <S4XLanguageSelectDialogWrapper
      onClose={() => dispatch({ type: 'close' })}
    />
  );

  return isOpen ? dialogComponent : null;
}
