export function fixHref(val?: string) {
  if (val === null || val === undefined) {
    return val;
  }

  let href = val
    .trim()
    .replace(/^((https|http):)?(\/\/)?artists\.spotify\.com/, '');
  if (href === '') href = '/'; // empty url probably refers to root, esp after ^^^
  return href;
}
