// ignore-string-externalization

import React from 'react';

export const LazyBanner = React.lazy(() =>
  import(/* webpackChunkName: "banner" */ './Banners').then(({ Banners }) => ({
    default: Banners,
  })),
);

export { BannerState } from './BannerState';

export { useBannerActions } from './useBannerActions';
