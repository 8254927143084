import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';

type Action = {
  type: 'open' | 'close';
};

const initialState = { isOpen: false };

function reducer(state = initialState, action: Action) {
  if (action.type === 'open') return { isOpen: true };
  if (action.type === 'close') return { isOpen: false };
  return state;
}

type LanguageSelectionContextType = {
  dispatch: React.Dispatch<Action>;
  isOpen: boolean;
};

const LanguageSelectionContext = createContext<LanguageSelectionContextType>({
  dispatch: () => {},
  isOpen: false,
});

export function LanguageSelectionProvider({ children }: PropsWithChildren) {
  const [{ isOpen }, dispatch] = useReducer(reducer, initialState);

  return (
    <LanguageSelectionContext.Provider value={{ dispatch, isOpen }}>
      {children}
    </LanguageSelectionContext.Provider>
  );
}

export function useLanguageSelectionContext() {
  return useContext(LanguageSelectionContext);
}
