// ignore-string-externalization

import { accessTokenManager } from '../../../../features/src/auth';
import { getHash, getVersion } from '../env';

export const defaultWebgateHeaders = {
  accept: 'application/json',
  'content-type': 'application/json',
  'spotify-app-version': `${getVersion()}.${getHash()}`.trim(),
  'app-platform': 'Browser',
};

export const WEBGATE_DOMAIN = `https://generic.wg.spotify.com`;

export { accessTokenManager };

export async function webgateFetch(input: RequestInfo, init?: RequestInit) {
  const req = new Request(input, init);
  const token = await accessTokenManager.get();

  for (const [key, value] of Object.entries(defaultWebgateHeaders)) {
    if (!req.headers.has(key)) req.headers.set(key, value);
  }

  if (token) {
    req.headers.set('authorization', `Bearer ${token}`);
  } else {
    req.headers.delete('authorization');
  }
  return fetch(req);
}
