// dimensions in format of string[]
export const setInitialDimensions = dimensions => {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'initial_datalayer_push',
      dimensions,
    });
  }
};

// opts in the format of category, label, and action
export const sendEvent = opts => {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: 'my_custom_event', opts });
  }
};
