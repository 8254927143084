// ignore-string-externalization

export function getSpotifyClientId() {
  return (
    process.env.REACT_APP_SPOTIFY_CLIENT_ID ||
    '6cf79a93be894c2086b8cbf737e0796b'
  );
}

export function getHash() {
  return process.env.REACT_APP_HASH || process.env.NODE_ENV;
}

export function getVersion() {
  return process.env.REACT_APP_VERSION || '0.0.0';
}
