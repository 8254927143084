// ignore-string-externalization

import React, { ReactNode } from 'react';

type LayoutProps = {
  children: ReactNode;
  preview?: boolean;
};

type LayoutContextType = {
  showNavBar: boolean;
  showFooter: boolean;
  navBarBackground?: string;
  navBarTextColor?: string;
  navBarBleed?: boolean;
  isPreview: boolean;
  setShowNavBar: (val: boolean) => void;
  setShowFooter: (val: boolean) => void;
  setNavBarBackground: (color: string) => void;
  setNavBarTextColor: (color: string) => void;
  setNavBarBleed: (val: boolean) => void;
  setIsPreview: (val: boolean) => void;
};

const LayoutContext = React.createContext<LayoutContextType>(
  {} as LayoutContextType,
);

export function useLayoutContext() {
  return React.useContext(LayoutContext);
}

export const LayoutState: React.FC<LayoutProps> = ({
  children,
  preview = false,
}) => {
  const [isPreview, setIsPreview] = React.useState(preview);
  const [showNavBar, setShowNavBar] = React.useState(true);
  const [showFooter, setShowFooter] = React.useState(true);
  const [navBarBleed, setNavBarBleed] = React.useState(false);
  const [navBarBackground, setNavBarBackground] = React.useState<
    string | undefined
  >();
  const [navBarTextColor, setNavBarTextColor] = React.useState<
    string | undefined
  >();

  return (
    <LayoutContext.Provider
      value={{
        showNavBar,
        showFooter,
        navBarBackground,
        navBarTextColor,
        navBarBleed,
        isPreview,
        setShowNavBar,
        setShowFooter,
        setNavBarBackground,
        setNavBarTextColor,
        setNavBarBleed,
        setIsPreview,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
