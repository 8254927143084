// ignore-string-externalization

import { hexToRgba } from '../../../features/src/color-conversion';
import {
  gold155,
  gray7,
  gray10,
  kleinBlue61,
  white,
  black,
  opacityBlack90,
  pink100,
  gray50,
  gray70,
  opacityBlack70,
  cssColorValue,
  ColorSet,
} from '@spotify-internal/encore-web';

const darkComponents = {
  app: 'dark',
  table: 'dark',
  tableRow: 'dark',
  buttonIcon: 'dark',
  loadingIndicator: 'dark',
  navBar: 'dark',
  navBarList: 'dark',
  navBarListItem: 'dark',
  dialogAlert: 'dark',
  dialogConfirmation: 'dark',
  formGroup: 'dark',
  // formInput: 'dark',
  button: 'dark',
};

const help = {
  link: {
    hover: gray70,
    divider: cssColorValue('backgroundPress'),
  },
  grayBox: {
    background: cssColorValue('decorativeSubdued'),
    text: black,
  },
  banner: {
    background: kleinBlue61,
    text: white,
    ctaText: black,
  },
  sideMenu: {
    currentCategoryIndicator: pink100,
    unselectedCategory: gray70,
    selectedCategory: black,
    text: black,
    background: cssColorValue('backgroundHighlight'),
  },
};

const searchBar = {
  background: white,
  border: gray70,
  suggestionHeader: opacityBlack70,
  icon: gray70,
};

// dark theme is the default
// everything required should be specified here
export const defaultTheme = {
  ...darkComponents,
  app: 'dark',
  loadingIndicator: 'dark',
  box: {
    background: gray10,
    text: white,
    tileOneBackground: gray10,
    tileTwoBackground: black,
  },
  cta: {
    background: white,
    text: gray7,
  },
  label: {
    text: gray10,
  },
  banner: {
    background: white,
    text: gray10,
  },
  layout: {
    background: opacityBlack90,
    text: white,
  },
  nav: {
    background: black,
    text: white,
    sideMenuBackground: black,
    sideMenuTextColor: white,
  },
  link: {
    color: white,
    hover: gray50,
    tap: hexToRgba(black, 0.3),
  },
  help,
  searchBar,
};

export type Theme = typeof defaultTheme & {
  help: {
    link: {
      divider: string;
      hover: string;
    };
    grayBox: {
      background: string;
      text: string;
    };
    sideMenu: {
      currentCategoryIndicator: string;
      unselectedCategory: string;
      selectedCategory: string;
    };
  };
};

export const darkTheme = defaultTheme;

/* light theme only applies to the current page area, not the navigation, etc */
export const lightTheme: Theme = {
  ...defaultTheme,
  loadingIndicator: 'light',
  dialogAlert: 'light',
  cta: {
    ...defaultTheme.cta,
    background: black,
    text: white,
  },
  box: {
    ...defaultTheme.box,
    background: white,
    text: black,
  },
  banner: {
    background: gray10,
    text: white,
  },
  link: {
    ...defaultTheme.link,
    color: black,
  },
};

export const TEMPLATE_THEMES: { [name: string]: Theme } = {
  Dark: darkTheme,
  Light: lightTheme,
};

// module themes get merged with the template theme
export const MODULE_THEMES: { [name: string]: Partial<Theme> } = {
  whiteOnBlue: {
    box: {
      ...defaultTheme.box,
      background: kleinBlue61,
      text: white,
    },
    cta: {
      ...defaultTheme.cta,
      background: white,
      text: kleinBlue61,
    },
  },
  blueOnWhite: {
    box: {
      ...defaultTheme.box,
      background: white,
      text: kleinBlue61,
    },
    cta: {
      ...defaultTheme.cta,
      background: kleinBlue61,
      text: white,
    },
  },
  blueOnBlack: {
    box: {
      ...defaultTheme.box,
      background: gray10,
      text: kleinBlue61,
    },
    cta: {
      ...defaultTheme.cta,
      background: kleinBlue61,
      text: gray10,
    },
  },
  blackOnWhite: {
    box: {
      ...defaultTheme.box,
      background: white,
      text: gray10,
      tileOneBackground: cssColorValue('backgroundHighlight'),
      tileTwoBackground: cssColorValue('decorativeSubdued'),
    },
    cta: {
      ...defaultTheme.cta,
      background: gray10,
      text: cssColorValue('decorativeSubdued'),
    },
    link: {
      ...defaultTheme.link,
      color: gray10,
    },
  },
  blackOnGold: {
    box: {
      ...defaultTheme.box,
      background: gold155,
      text: gray10,
    },
    cta: {
      ...defaultTheme.cta,
      background: gray10,
      text: gold155,
    },
  },
  goldOnBlack: {
    box: {
      ...defaultTheme.box,
      background: gray10,
      text: gold155,
    },
    cta: {
      ...defaultTheme.cta,
      background: gold155,
      text: gray10,
    },
  },
  whiteOnBlack: {
    box: {
      ...defaultTheme.box,
      background: gray10,
      text: white,
      tileOneBackground: gray10,
      tileTwoBackground: black,
    },
    cta: {
      ...defaultTheme.cta,
      background: white,
      text: gray7,
    },
    link: {
      ...defaultTheme.link,
      color: white,
    },
  },
  blackOnGray: {
    box: {
      ...defaultTheme.box,
      background: cssColorValue('decorativeSubdued'),
      text: gray10,
    },
    cta: {
      ...defaultTheme.cta,
      background: white,
      text: gray7,
    },
  },
};

// closest encore color set
export const MODULE_COLOR_SETS: { [name: string]: ColorSet } = {
  whiteOnBlue: 'brightAccent',
  blueOnBlack: 'mutedAccent',
  goldOnBlack: 'mutedAccent',
  whiteOnBlack: 'invertedDark',
  blueOnWhite: 'base',
  blackOnWhite: 'base',
  blackOnGray: 'base',
  blackOnGold: 'warning',
};
