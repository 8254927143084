import React, { createContext, useContext, FunctionComponent } from 'react';

const Context = createContext(false);

export const RtlProvider = Context.Provider;

export function useRtl() {
  return useContext(Context);
}

export type InjectedRtlProps = {
  rtl: boolean;
};

export function withRtl<Props extends InjectedRtlProps>(
  ClassComponent: React.ComponentType<Props>,
): FunctionComponent<Omit<Props, keyof InjectedRtlProps>> {
  return function WithRtl(props) {
    const rtl = useRtl();
    return <ClassComponent {...(props as Props)} rtl={rtl} />;
  };
}
