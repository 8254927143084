export const isWebView = () => {
  let userAgent: string;
  if (typeof window !== 'undefined') {
    userAgent = window.navigator.userAgent;
  } else {
    userAgent = '';
  }
  const rules = [
    'WebView',
    '(iPhone|iPod|iPad)(?!.*Safari)',
    'Android.*(wv|.0.0.0)',
    'Linux; U; Android',
  ];
  return !!userAgent.match(new RegExp(`(${rules.join('|')})`, 'ig'));
};
