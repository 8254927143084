import React from 'react';
import { FrodorModulesProvider as FrodorModulesProviderBase } from '@spotify-internal/frodor-modules';
import { InteractiveElement } from './InteractiveElement';
export const FrodorModulesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <FrodorModulesProviderBase
      preloadVideo="none"
      dir="ltr"
      InteractiveElement={InteractiveElement}
    >
      {children}
    </FrodorModulesProviderBase>
  );
};
