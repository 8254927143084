import {
  ButtonSecondary,
  IconFlag,
  PopoverTrigger,
  Popover,
  ButtonTertiary,
  IconInformationAlt,
  FormToggle,
  IconX,
  Text,
  KeyboardDetectionProvider,
} from '@spotify-internal/encore-web';
import { RemoteConfigProvider } from '@spotify-internal/remote-config-integration-react';
import { Configuration } from 'mailslurp-client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getConfiguration } from '../RemoteConfig';
import { defaultFlags } from './defaultFlags';
import { useFeatureFlags } from './FeatureFlagsContext';
import { useFeatureFlagsUsers } from './useFeatureFlagsUsers';
import { useHideMenu } from './useHideMenu';

const Trigger = styled(ButtonSecondary)`
  position: fixed;
  bottom: 60px;
  right: 60px;
  z-index: 100;
  background-color: white;
`;

const Modal = styled.ul`
  position: fixed;
  border-radius: 4px;
  bottom: 45px;
  right: 60px;
  padding: 10px;
  background-color: white;
  width: auto;
  height: auto;
  list-style: none;
  z-index: 101;
  cursor: pointer;

  input {
    padding: 20px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.div`
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
`;

const Menu = () => {
  const featureFlagsUser = useFeatureFlagsUsers();
  const hideMenu = useHideMenu();
  const { storedFlags, setStoredFlags } = useFeatureFlags();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  if (!featureFlagsUser || hideMenu) {
    return null;
  }

  return (
    <KeyboardDetectionProvider>
      <div className="encore-creator-light-theme">
        <Trigger
          aria-label="feature-flags-menu"
          iconOnly={IconFlag}
          onClick={() => setIsMenuOpen(true)}
          semanticColor="textBrightAccent"
        />

        {isMenuOpen && (
          <Modal>
            <PopoverTrigger
              placement="startTop"
              style={{ float: 'right' }}
              onShow={() => setIsInfoOpen(true)}
              onHide={() => setIsInfoOpen(false)}
              overlay={
                isInfoOpen && (
                  <Popover
                    onClose={() => setIsInfoOpen(false)}
                    arrow="endBottom"
                    large
                  >
                    This menu is only visible to employees. To hide this menu,
                    add ?hide_feature_flags_menu=true to the url. To show it
                    again, refresh the page without the url param. Clicking
                    reset will return the flags to default settings as they are
                    set in production.
                  </Popover>
                )
              }
            >
              <ButtonTertiary
                condensedAll
                size="small"
                iconOnly={IconInformationAlt}
                aria-label="Help"
              />
            </PopoverTrigger>
            <Title>
              <Text variant="bodySmallBold">Feature Flag Settings</Text>
            </Title>

            <>
              {storedFlags &&
                Object.keys(storedFlags).map(flag => (
                  <li key={flag} id={flag}>
                    <FormToggle
                      checked={storedFlags?.[flag]}
                      onChange={() => {
                        setStoredFlags?.({
                          ...storedFlags,
                          [flag]: !storedFlags[flag],
                        });
                      }}
                    >
                      <Text variant="bodySmall">{flag}</Text>
                    </FormToggle>
                  </li>
                ))}
            </>
            <Buttons>
              <ButtonTertiary
                onClick={() => setStoredFlags?.(defaultFlags)}
                condensed
                semanticColor="textSubdued"
                aria-label="reset"
              >
                reset
              </ButtonTertiary>
              <ButtonTertiary
                onClick={() => setIsMenuOpen(false)}
                condensed
                aria-label="close menu"
                semanticColor="textSubdued"
                iconOnly={IconX}
              />
            </Buttons>
          </Modal>
        )}
      </div>
    </KeyboardDetectionProvider>
  );
};

export const FeatureFlagsMenu = () => {
  const [config, setConfig] = useState<Configuration | null>(null);
  useEffect(() => {
    getConfiguration().then(configuration =>
      setConfig(configuration as Configuration),
    );
  }, []);
  if (!config) {
    return null;
  }
  return (
    // @ts-ignore
    <RemoteConfigProvider configuration={config!}>
      <Menu />
    </RemoteConfigProvider>
  );
};
