// ignore-string-externalization

import React, { ReactNode, Reducer, useContext, useReducer } from 'react';
import { BannerOptions } from './BannerOptions';

export enum BannerType {
  WARNING,
  ANNOUNCEMENT,
  ERROR,
  SUCCESS,
  INFO,
}

export enum ActionType {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}

export interface ShowAction {
  type: ActionType.SHOW;
  message: ReactNode;
  bannerType: BannerType;
  bannerOptions: BannerOptions;
}

export interface HideAction {
  type: ActionType.HIDE;
  bannerId?: string;
}

type Action = ShowAction | HideAction;

export interface SingleBannerState {
  id: string;
  type: BannerType;
  message: ReactNode;
  options: BannerOptions;
}

export interface State {
  autogenIdCounter: number;
  banners: SingleBannerState[];
}

const initialState: State = {
  autogenIdCounter: 0,
  banners: [],
};

const BannerContext = React.createContext<[State, React.Dispatch<Action>]>([
  initialState,
  action => action,
]);

const reducer: Reducer<State, Action> = (
  state = initialState,
  action: Action,
) => {
  switch (action.type) {
    case ActionType.SHOW: {
      const bannerState: SingleBannerState = {
        id:
          action.bannerOptions.id || `autogen-banner-${state.autogenIdCounter}`,
        message: action.message,
        type: action.bannerType,
        options: action.bannerOptions,
      };
      return {
        // we increment regardless of whether the id was autogenerated or not (since nothing should
        // rely on predictable autogen'd IDs).
        autogenIdCounter: state.autogenIdCounter + 1,
        banners: [
          ...state.banners.filter(({ id }) => id !== bannerState.id),
          bannerState,
        ],
      };
    }
    case ActionType.HIDE:
      return {
        ...state,
        banners: action.bannerId
          ? state.banners.filter(b => b.id !== action.bannerId)
          : [],
      };
    default:
      return state;
  }
};

export const useBannerState = () => useContext(BannerContext);

export const BannerState = ({
  children = null,
}: {
  children: React.ReactNode;
}) => (
  <BannerContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </BannerContext.Provider>
);
