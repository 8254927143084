// ignore-string-externalization

import { ReactNode, useMemo } from 'react';
import { ActionType, BannerType, useBannerState } from './BannerState';
import { BannerOptions, defaultOptions } from './BannerOptions';

export const useBannerActions = () => {
  const [, dispatch] = useBannerState();

  return useMemo(() => {
    const hideAll = () => dispatch({ type: ActionType.HIDE });

    const hide = (bannerId: string) =>
      dispatch({ type: ActionType.HIDE, bannerId });

    const curriedShow =
      (bannerType: BannerType) =>
      (message: ReactNode, options: Partial<BannerOptions> = {}) =>
        dispatch({
          type: ActionType.SHOW,
          bannerType,
          message,
          bannerOptions: { ...defaultOptions, ...options },
        });

    return {
      hideAll,
      hide,
      showSuccess: curriedShow(BannerType.SUCCESS),
      showError: curriedShow(BannerType.ERROR),
      showInfo: curriedShow(BannerType.INFO),
      showWarning: curriedShow(BannerType.WARNING),
      showAnnouncement: curriedShow(BannerType.ANNOUNCEMENT),
    };
  }, [dispatch]);
};
