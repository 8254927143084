import React, { ReactNode } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { makeJpgUrl } from '../Contentful';
import { isContentfulImage } from '../utils';
import { useT } from '@spotify-internal/i18n-core';
import styled from 'styled-components';

export interface PageHeadProps {
  title: string[] | string;
  description?: string;
  image?: string;
  linkedData?: {};
  children?: ReactNode;
  canonicalUrl?: string;
}

export const BLOG_URL = '/blog.rss';
export const DEFAULT_SOCIAL_CARD = '/socialcard.jpg';

const LQAHead = styled.div`
  .metaDesc {
    display: block;
    content: 'Charset: ' attr(charset);
    transform: translateX(25px);
  }

  [name][content]::after {
    content: attr(content);
  }
`;

export const PageHead: React.FC<PageHeadProps> = (props: PageHeadProps) => {
  const t = useT();
  const router = useRouter();

  const titleParts =
    typeof props.title === 'string' ? [props.title] : props.title;
  const title = [
    ...titleParts.filter(Boolean),
    t('FRODOR_bfbc75', 'Spotify for Artists', ''),
  ].join(' – ');
  let image = (props.image || DEFAULT_SOCIAL_CARD).replace(/^\/\//, 'https://');
  if (isContentfulImage(image)) {
    // contentful asset, convert to jpg
    image = makeJpgUrl(image);
  }
  let description;

  if (router.query && router.query._lqa === 'pageHead') {
    description = t(
      'FRODOR_e7a647',
      'Get more out of Spotify with tools & tips for artists and their teams.',
      '',
    );
  } else {
    description =
      props.description ||
      t(
        'FRODOR_e7a647',
        'Get more out of Spotify with tools & tips for artists and their teams.',
        '',
      );
  }
  const linkedData = props.linkedData && {
    '@context': 'http://schema.org',
    ...props.linkedData,
  };

  // these are only the dynamic elements of the head - static and default elements are written
  // in _document.tsx and overridden here
  return router.query && router.query._lqa === 'pageHead' ? (
    <LQAHead>
      <meta
        name="description"
        content={description}
        key="meta.description"
        className="metaDesc"
      />
    </LQAHead>
  ) : (
    <Head>
      {props.canonicalUrl && <link rel="canonical" href={props.canonicalUrl} />}

      <title key="title">{title}</title>
      <meta name="description" content={description} key="meta.description" />

      <meta property="og:title" content={title} key="meta.og:title" />
      <meta
        property="og:description"
        content={description}
        key="meta.og:description"
      />
      <meta property="og:image" content={image} key="meta.og:image" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:image" content={image} key="meta.twitter:image" />
      <meta property="twitter:title" content={title} key="meta.twitter:title" />
      <meta
        property="twitter:description"
        content={description}
        key="meta.twitter:description"
      />
      <meta name="twitter:site" content="@spotifyartists" />
      <meta name="twitter:app:id:iphone" content="324684580" />
      <meta name="twitter:app:id:googleplay" content="com.spotify.music" />

      <meta name="apple-itunes-app" content="app-id=1222021797" />
      <meta name="twitter:app:id:googleplay" content="com.spotify.music" />

      <link
        rel="alternate"
        type="application/rss+xml"
        title={t(
          'FRODOR_3e864f',
          'All Posts – News – Spotify for Artists',
          'Posts mean blog post',
        )}
        href={`${router.locale ? `/${router.locale}` : ''}${BLOG_URL}`}
        key="link.blogUrl"
      />

      {linkedData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(linkedData) }}
          key="script.linkedData"
        />
      )}

      {props.children && props.children}
    </Head>
  );
};
