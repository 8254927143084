import { useEffect, useState } from 'react';
import { accessTokenManager } from '../accessTokenManager';

export function useLoggedIn() {
  const [token, setToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    accessTokenManager.get().then(t => {
      return setToken(t);
    });
  }, []);
  return !!token;
}
