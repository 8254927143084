import React from 'react';
import { LanguageSelectionDialog as S4XLanguageSelectionDialog } from '@spotify-internal/s4x-masthead';
import { useRouter } from 'next/router';
import { useTracking } from '../../../../../src/features/Tracking';

interface Props {
  onClose: () => void;
}

export function S4XLanguageSelectDialogWrapper({ onClose }: Props) {
  const { locale: currentLocale, asPath, basePath } = useRouter();
  const { eventHandlerWithAction } = useTracking();

  function getHref(locale: string) {
    return `${basePath}/${locale}${asPath}`;
  }

  return (
    <S4XLanguageSelectionDialog
      onClose={onClose}
      currentLocale={currentLocale!}
      getHref={getHref}
      onLanguageSelect={locale => {
        document.cookie = `NEXT_LOCALE=${locale};path=/;max-age=31536000`;
        eventHandlerWithAction(
          'Frodor - module - Language Picker',
          'select-language',
          `select-language: ${locale}`,
        )();
      }}
    />
  );
}
