import { TokenProviderResult } from './types';

export async function getTestAuthToken(): Promise<TokenProviderResult> {
  // fail when not supported
  if (process.env.NODE_ENV !== 'test') {
    throw new Error('failed to get access token');
  }

  // logged out in tests
  return [undefined, Infinity];
}
