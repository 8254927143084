export const startAxeOverlay = () => {
  // initialize during development
  if (process.env.NODE_ENV === 'development') {
    // todo: always enable in development
    if (localStorage.axeOverlayEnabled === 'true') {
      // eslint-disable-next-line no-console
      console.warn(
        `AxeOverlay is enabled. Set localStorage.axeOverlayEnabled = false to disable.`,
      );

      import(
        /* webpackChunkName: "axe" */ '@spotify-internal/axe-overlay'
      ).then(({ initializeAxeOverlay }) => {
        initializeAxeOverlay(/* axe.RunOptions */);
      });
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `AxeOverlay is disabled. Set localStorage.axeOverlayEnabled = true to enable.`,
      );
    }
  }
};
