// ignore-string-externalization
function clamp(v: number, min: number, max: number) {
  // I wouldn't ordinarily do this but nested ternaries is forbidden
  // and my least favourite thing about js is that if is a statement
  // rather than an expression
  if (v > max) {
    return max;
  }
  if (v < min) {
    return min;
  }
  return v;
}

export function hexToRgba(hex: string, _alpha?: number) {
  const isSingleDigitPrecision = hex.length <= 5 && hex.length >= 3;
  const hexParseRegex = /#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})?/;
  const hexParseSingleDigit = /#?([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])?/;
  const parsed = isSingleDigitPrecision
    ? hexParseSingleDigit.exec(hex)
    : hexParseRegex.exec(hex);
  if (parsed === null) {
    throw new Error('Failed to parse hex code');
  }
  const [_, _r, _g, _b, _a] = Array.from(parsed);
  const chosenAlpha = _a
    ? (parseInt(_a, 16) * (isSingleDigitPrecision ? 16 : 1)) / 255
    : 1.0;
  const [r, g, b, a] = [
    parseInt(_r, 16) * (isSingleDigitPrecision ? 16 : 1),
    parseInt(_g, 16) * (isSingleDigitPrecision ? 16 : 1),
    parseInt(_b, 16) * (isSingleDigitPrecision ? 16 : 1),
    _alpha ? clamp(_alpha, 0, 1) : chosenAlpha,
  ];
  return `rgba(${r}, ${g}, ${b}, ${a.toFixed(2)})`;
}
