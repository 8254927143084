// ignore-string-externalization

import React from 'react';
import { Provider } from 'react-redux';
import { Page } from './Page';

export const PageWithRedux = (ctx: $TSFixMe) => {
  const { store, props, Component } = ctx;
  return (
    // @ts-ignore
    <Provider store={store}>
      <Page
        // @ts-ignore
        props={props}
        Component={Component}
      />
    </Provider>
  );
};
