import React, { ReactNode } from 'react';
import { S4AMastheadProvider } from '@spotify-internal/s4x-masthead';
import { getSpotifyClientId } from '../@mrkt/features/env';
import { useLanguageSelectionContext } from '../../features/src/i18n/components/LanguageSelection';
import { useLocale, useRtl } from '../../features/src/i18n';
import { getLanguage } from './getLanguage';
import { InteractiveElement } from './InteractiveElement/index';

export const MastheadProvider = ({
  children,
  contentfulEntryId,
}: {
  children: ReactNode;
  contentfulEntryId?: string;
}) => {
  const locale = useLocale();
  const rtl = useRtl();
  const { dispatch } = useLanguageSelectionContext();
  return (
    <S4AMastheadProvider
      InteractiveElement={InteractiveElement}
      clientId={getSpotifyClientId()}
      language={{
        dir: rtl ? 'rtl' : 'ltr',
        locale: getLanguage(locale),
        onClick: () => dispatch({ type: 'open' }),
        marketSelectReturnToPath: '',
        useCountryRegionPicker: false,
      }}
      contentfulEntryId={contentfulEntryId}
    >
      {children}
    </S4AMastheadProvider>
  );
};
