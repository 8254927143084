export interface BannerOptions {
  id?: string;
  showDismissButton: boolean;
  dismissOnRouteChange: boolean;
  compact: boolean;
  onHide?: () => boolean;
}

export const defaultOptions: BannerOptions = {
  showDismissButton: true,
  dismissOnRouteChange: true,
  compact: false,
};
