// ignore-string-externalization

// This file should be deleted in favor of frodor-modules

import React from 'react';
import qs from 'query-string';
import memoize from 'lodash/memoize';
import uniqBy from 'lodash/uniqBy';
import * as contentful from 'contentful';

const PAGE_SIZE = 10;

type Filters = { [key: string]: string | boolean };

type EntryQuery = {
  contentType: string | string[];
  filters?: Filters;
  select?: string[];
  locale?: string;
  preview?: boolean;
};

type EntryListQuery = {
  contentType?: string | string[];
  order?: string;
  filters?: Filters;
  limit?: number;
  skip?: number;
  filterNulls?: boolean;
  select?: string[];
  locale?: string;
  preview?: boolean;
  query?: string;
  linksToEntry?: string;
};

export type PagedData<EntryType> = {
  limit: number;
  skip: number;
  total: number;
  entries: EntryType[];
  totalUnfiltered: number;
};

export type ContentfulAsset = {
  file: {
    url: string;
    details: {
      size: number;
      image: {
        width?: number;
        height?: number;
      };
    };
    fileName?: string;
    contentType?: string;
  };
  title?: string;
  description?: string;
  contentType?: string;
};

const getPreviewAPI = () => {
  return contentful.createClient({
    host: process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    space: process.env.REACT_APP_CONTENTFUL_SPACE || '',
    accessToken: process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN!,
    resolveLinks: true,
    retryOnError: false,
  });
};

const getAPI = memoize(() => {
  return contentful.createClient({
    host: process.env.REACT_APP_CONTENTFUL_HOST,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    space: process.env.REACT_APP_CONTENTFUL_SPACE || '',
    accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN!,
    resolveLinks: true,
    retryOnError: false,
  });
});

const getSearchAPI = memoize(() => {
  return contentful.createClient({
    host: process.env.REACT_APP_CONTENTFUL_HOST,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    space: process.env.REACT_APP_CONTENTFUL_SPACE || '',
    accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN!,
    resolveLinks: false,
    retryOnError: true,
    timeout: 10000,
  });
});

export async function fetchEntry<EntryType>(
  props: EntryQuery,
): Promise<EntryType | null> {
  const data = await fetchEntryList<EntryType>({
    ...props,
    limit: 1,
    skip: 0,
  });

  const entries = data.entries;

  if (!entries || !entries.length) {
    return null;
  } else if (entries.length > 1) {
    // eslint-disable-next-line no-console
    console.error(
      `${entries.length} items returned for ${props.contentType} query`,
    );
  }

  return entries[0];
}

export async function fetchEntryList<EntryType>({
  contentType,
  order,
  filters = {},
  limit = 10,
  skip = 0,
  filterNulls = true,
  select,
  query,
  locale,
  preview = false,
  linksToEntry,
}: EntryListQuery): Promise<PagedData<EntryType>> {
  const params: any = {
    limit,
    skip,
    order,
    select,
    query,
    locale,
    links_to_entry: linksToEntry,
  };

  for (const [key, val] of Object.entries(filters)) {
    if (val !== null) {
      if (key.startsWith('sys')) {
        params[key] = val;
      } else {
        params[`fields.${key}`] = val;
      }
    }
  }

  return apiFetchEntries<EntryType>(contentType, params, filterNulls, preview);
}

type ApiFetchEntriesParams = { [key: string]: any };

async function apiFetchEntries<EntryType>(
  contentType: string | string[] = '',
  params = {} as ApiFetchEntriesParams,
  filterNulls = true,
  preview = false,
): Promise<PagedData<EntryType>> {
  const fetchParams: any = { include: 4, ...params };

  if (contentType) {
    if (Array.isArray(contentType)) {
      fetchParams['sys.contentType.sys.id[in]'] = contentType.join(',');
    } else {
      fetchParams.content_type = contentType;
    }
  }

  let api;
  if (preview) {
    api = await getPreviewAPI();
  } else if (params.query) {
    api = await getSearchAPI();
  } else {
    api = await getAPI();
  }

  const entries = await api.getEntries<EntryType>(fetchParams);

  if (filterNulls) {
    entries.items = entries.items.filter(
      (e: contentful.Entry<EntryType>) =>
        e !== null && Boolean(e.sys.contentType),
    );
  }

  const circularJsonSafeEntries = entries.stringifySafe();
  const parsedEntires = JSON.parse(circularJsonSafeEntries);

  const items = parsedEntires.items.map(
    (entry: any) =>
      expand(
        entry.sys.id,
        entry.sys.contentType?.sys.id || '',
        entry.fields,
      ) as EntryType,
  );

  return {
    limit: entries.limit,
    skip: entries.skip,
    total: entries.total,
    entries: items,
    totalUnfiltered: entries.total,
  } as PagedData<EntryType>;
}

const isContentful = (type: 'Entry' | 'Asset' | 'Link', v: any) =>
  v?.sys?.type === type;

/*
 * Formats a Contentful Entry of the form:
 *  {
 *    sys: { id: 'abc', contentType: { sys: { id: '123' } }, ... }
 *    fields: { some: 'value',  nested: { sys: { ... }, fields: { ... } }
 *  }
 * to a flatter structure with linked Entries/Assets also formatted
 *  {
 *    id: 'abc',
 *    contentType: '123',
 *    some: 'value',
 *    nested: { id: ..., contentType: ..., ... }
 *  }
 *
 * Assumptions:
 *  - the contentful client has been configured with `resolveLinks: true`
 *    - this ensures that valid linked Entries are resolved to their values
 *  - the entry has been fetched with `#getEntries` and processed with `#stringifySafe`
 *    - this ensures that circular entries are shown as Links rather than being resolved, avoiding infinite loops
 */
export function expand(id: string, ctype: string, fields: any) {
  if (!fields) {
    return fields;
  }

  fields.id = id;
  fields.contentType = ctype;

  for (const [key, val] of Object.entries(fields) as [string, any][]) {
    if (val === null || val === undefined) {
      continue;
    } else if (Array.isArray(val)) {
      fields[key] = val.map(v =>
        typeof v === 'object' && v.sys && v.fields && v.sys?.contentType?.sys
          ? expand(v.sys.id, v.sys.contentType.sys.id, v.fields)
          : v,
      );
    } else if (isContentful('Entry', val)) {
      fields[key] = expand(val.sys.id, val.sys.contentType.sys.id, val.fields);
    } else if (isContentful('Asset', val)) {
      const asset = val.fields as ContentfulAsset;
      fields[key] = asset;
    }
  }

  // make arrays of objects for field keys of the form:
  // foo__1__bar, foo__1__baz, foo__2__bar, foo__2__baz, etc.
  for (const [key, val] of Object.entries(fields)) {
    const [arrKey, indexKey, propKey] = key.split('__');
    if (arrKey && indexKey && propKey) {
      const i = parseInt(indexKey, 10) - 1;
      const arr = fields[arrKey] || [];
      arr[i] = arr[i] || {};
      arr[i][propKey] = val;
      fields[arrKey] = arr;
      delete fields[key];
    }
  }

  return fields;
}

export function makeWebpUrl(imgUrl: string, quality = 80) {
  const { url, query } = qs.parseUrl(imgUrl);
  delete query.fl;
  query.fm = 'webp';
  query.q = `${quality}`;
  return `${url}?${qs.stringify(query)}`;
}

export function makeJpgUrl(imgUrl: string, fl?: string, quality?: number) {
  const { url, query } = qs.parseUrl(imgUrl);
  query.fm = 'jpg';
  if (fl) query.fl = `${fl}`;
  if (quality) query.q = `${quality}`;
  return `${url}?${qs.stringify(query)}`;
}

export function makeImageSizeUrl(
  imgUrl: string,
  width?: number,
  height?: number,
  fit?: string,
  focus?: string,
) {
  const { url, query } = qs.parseUrl(imgUrl);
  if (width !== undefined && !isNaN(width)) query.w = `${width}`;
  if (height !== undefined && !isNaN(height)) query.h = `${height}`;
  if (fit) query.fit = `${fit}`;
  if (focus) query.f = `${focus}`;
  return `${url}?${qs.stringify(query)}`;
}

export function makeContentfulAsset({
  url,
  contentType,
  width,
  height,
  size = -1,
  description = '',
}: {
  url: string;
  contentType: string;
  width?: number;
  height?: number;
  size?: number;
  description?: string;
}): ContentfulAsset {
  return {
    file: {
      contentType,
      url,
      details: {
        size,
        image: {
          width,
          height,
        },
      },
    },
    description,
    contentType,
  };
}

export function usePagedEntryList<EntryType>(query: EntryListQuery) {
  const [entries, setEntries] = React.useState<EntryType[]>();
  const [pageNum, setPageNum] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const page = React.useRef<PagedData<EntryType> | null>(null);
  const pageSize = query.limit || PAGE_SIZE;

  const hasNextPage = () =>
    (!page.current && true) || pageNum * pageSize < page.current!.total;

  const getNextPage = async () => {
    if (!hasNextPage) return;
    const nextPageNum = pageNum + 1;
    const q = {
      ...query,
      skip: (nextPageNum - 1) * pageSize,
    };

    setLoading(true);
    fetchEntryList<EntryType>(q).then(data => {
      const newEntries = [...(entries || []), ...data.entries].filter(Boolean);

      page.current = data;
      setEntries(uniqBy(newEntries, 'id'));
      setPageNum(nextPageNum);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    getNextPage();
  }, [pageNum]);

  return {
    entries,
    page,
    pageNum,
    pageSize,
    loading,
    hasNextPage,
    getNextPage,
  };
}
