import React from 'react';
import { Footer } from '@spotify-internal/s4x-masthead';
import { cssColorValue } from '@spotify-internal/encore-web';

export const MastheadFooter: React.FC = () => {
  return (
    <Footer
      colors={{
        secondaryText: cssColorValue('textSubdued'),
      }}
    />
  );
};
