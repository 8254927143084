// ignore-string-localization

import React, { ReactNode, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Banners } from '../../@mrkt/features/Banner/Banners';
import { getLinkStyle } from '../ui';
import { Main } from './Main';
import { useRouteBanners } from './useRouteBanners';
import { useLayoutContext } from './LayoutState';
import { PreviewLink } from './PreviewLink';
import { useRouter } from 'next/router';
import { MastheadHeader } from './MastheadHeader';
import { MastheadFooter } from './MastheadFooter';
import { LanguageSelectionDialogWrapper } from '../../../features/src/i18n/components/LanguageSelection/LanguageSelectionDialogWrapper';
import { getPageId } from '../ubi/getPageId';
import { UbiWarningBanner } from '../ubi/ubiWarningBanner';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.layout.background};
  color: ${({ theme }) => theme.layout.text};
  -webkit-font-smoothing: antialiased;
`;

type LayoutProps = {
  children: ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const router = useRouter();
  const { showNavBar, showFooter, isPreview } = useLayoutContext();
  // const s4xMastheadRollout = useS4XMastheadRollout();
  const [showNoPageIdBanner, setShowNoPageIdBanner] = useState(
    isPreview &&
      // @ts-ignore
      children?.[1]?.props.template &&
      getPageId(router.asPath).includes('unknown'),
  );

  useRouteBanners();

  // plain mode: shows no nav/footer to comply with apple terms of service
  // when linked from the mobile app
  const plainMode = 'plain' in (router.query || {});
  return (
    <>
      <LayoutContainer>
        {!plainMode && showNavBar && <MastheadHeader />}
        <Banners />

        {showNoPageIdBanner && (
          <UbiWarningBanner onClose={() => setShowNoPageIdBanner(false)} />
        )}
        <Main>{children}</Main>
        {!plainMode && showFooter && <MastheadFooter />}
        {isPreview && process.browser && <PreviewLink />}
        <LanguageSelectionDialogWrapper />
      </LayoutContainer>
    </>
  );
};
