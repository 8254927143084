// ignore-string-externalization

import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import {
  GlobalStyles,
  KeyboardDetectionProvider,
} from '@spotify-internal/encore-web';
import { BannerState } from '../../@mrkt/features/Banner';
import { darkTheme, lightTheme } from '../Theme';
import { Track } from '../Tracking';
import { Layout } from './Layout';
import { LayoutState } from './LayoutState';
import { Configuration } from '@spotify-internal/remote-config-properties';
import { getConfiguration } from '../../../features/src/RemoteConfig/RemoteConfig';
import { RemoteConfigProvider } from '@spotify-internal/remote-config-integration-react';

// Layout by default renders a dark theme and generic page components like a header and footer.
// In order to sidestep this and render a completely blank layout, add the URL of the page to this array.
const BLANK_LAYOUT_PATHS = ['/m/continue-authentication'];

export const Page: React.FC = (ctx: any) => {
  const router = useRouter();
  const { props, Component } = ctx;
  const isBlankLayout = BLANK_LAYOUT_PATHS.includes(router.pathname);
  const [config, setConfig] = useState<Configuration | null>(null);
  useEffect(() => {
    getConfiguration().then(setConfig);
  }, []);

  return (
    // @ts-ignore
    <RemoteConfigProvider configuration={config}>
      <Track>
        <BannerState>
          <KeyboardDetectionProvider>
            <LayoutState preview={props?.preview}>
              {isBlankLayout ? (
                <ThemeProvider theme={lightTheme}>
                  <Component {...props} />
                </ThemeProvider>
              ) : (
                <ThemeProvider theme={darkTheme}>
                  <Layout>
                    <GlobalStyles />
                    <Component {...props} />
                  </Layout>
                </ThemeProvider>
              )}
            </LayoutState>
          </KeyboardDetectionProvider>
        </BannerState>
      </Track>
    </RemoteConfigProvider>
  );
};
