// ignore-string-localization

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { screenSmMin } from '@spotify-internal/encore-web';

const MainWrapper = styled.main`
  flex: 1;
  display: flex;
`;

const MainContainer = styled.div`
  @media (min-width: ${screenSmMin}) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  flex: 1;
  max-width: 100%;
`;

type Props = { children: ReactNode };

export const Main: React.FC<Props> = ({ children }: Props) => {
  return (
    <MainWrapper>
      <MainContainer>{children}</MainContainer>
    </MainWrapper>
  );
};
