import Router from 'next/router';
import { NavigationReason } from '@spotify-internal/ubi-types-js';
import { initTransport, ubiLogger } from './ubiLogger';
import { getPageId } from './getPageId';

function shouldIgnore() {
  return (
    // ignore if no pathname
    !Router.pathname ||
    // ignore index route since index route will always redirect to /home or /c
    Router.pathname === '/'
  );
}

async function logNavigation() {
  if (shouldIgnore()) {
    return;
  }
  ubiLogger.logNavigation(
    {
      navigationalRoot: 'mrkt/frontdoor',
      navigationReason: NavigationReason.UNKNOWN,
    },
    {
      pageId: getPageId(Router.asPath),
      entityUri: window.location.href,
    },
  );
}

Router.ready(async () => {
  await initTransport();

  // log initial navigation
  logNavigation();

  // log navigation for every route change
  Router.events.on('routeChangeComplete', logNavigation);
});
