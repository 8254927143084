// ignore-string-externalization

import React, { PropsWithChildren } from 'react';
import qs from 'query-string';
import { marked } from 'marked';
import getCookies from 'next-cookies';
import { NextPageContext } from 'next';
import { css } from 'styled-components';

export const youtubeIdRegex =
  /^https:\/\/(?:www\.youtube\.com\/watch\?v=|youtu\.be\/)([^/&\s]+)/i;

export async function apiCall(url: string, qparams = {}) {
  const baseUrl = process.browser ? '' : process.env.REACT_APP_API_BASE_URL;
  const fetchUrl = `${baseUrl}/api/${url}?${qs.stringify(qparams)}`;
  const resp = await fetch(fetchUrl);
  const data = await resp.json();
  return data;
}

export const withoutProps =
  (
    Component: React.ComponentType<PropsWithChildren<{}>>,
    ...excludeProps: string[]
  ): React.FC<{ [key: string]: any }> =>
  props => {
    const { children, ...compProps } = props;
    for (const prop of excludeProps) {
      delete compProps[prop];
    }
    return <Component {...compProps}>{children}</Component>;
  };

// super-simple markdown formatter, mostly just line breaks, bold and italics
export function formatText(str?: string | null) {
  return marked.parseInline((str || '').trim().replace(/(\\n|\n)/g, '<br />'), {
    xhtml: true,
    smartypants: true,
  });
}

// fix common mistakes in href's entered from contentful
export function fixHref(val?: string) {
  if (val === null || val === undefined) {
    return val;
  }

  let href = val
    .trim()
    .replace(/^((https|http):)?(\/\/)?artists\.spotify\.com/, '');
  if (href === '') href = '/'; // empty url probably refers to root, esp after ^^^
  return href;
}

export const getTallest = (
  refCollection: Array<React.MutableRefObject<Element | undefined>>,
) => {
  let tallestRef = 0;
  refCollection.forEach((ref: React.MutableRefObject<Element | undefined>) => {
    if (ref.current) {
      ref.current.classList.remove('tallest');
      const thisRefHeight = ref.current.getBoundingClientRect().height;
      if (thisRefHeight > tallestRef) {
        tallestRef = thisRefHeight;
      }
    }
  });
  return tallestRef;
};

export const setTallestClass = (
  refCollection: Array<React.MutableRefObject<Element | undefined>>,
) => {
  refCollection.forEach((ref: React.MutableRefObject<Element | undefined>) => {
    if (ref.current) {
      ref.current.classList.add('tallest');
    }
  });
};

export function isPreview(context: NextPageContext) {
  const cookies = getCookies(context);
  return Boolean(cookies.__prerender_bypass && cookies.__next_preview_data);
}

export const getAuthorDateString = (author: string, releaseDate: string) => {
  if (author && releaseDate && releaseDate !== 'Invalid Date') {
    return `${author} \/ ${releaseDate}`;
  } else if (author) {
    return author;
  } else if (releaseDate !== 'Invalid Date') {
    return releaseDate;
  }
  return '';
};

export const transformScaleUp = `transform: scale(1.05);`;

export function isContentfulImage(url: string) {
  return (
    url &&
    (url.includes('images.ctfassets.net') ||
      url.includes('images.contentful.com'))
  );
}

export const isValidPath = (url: string | null | undefined): boolean => {
  if (url) {
    const decoded = decodeURIComponent(url);
    return (
      !decoded.includes('\\') &&
      !decoded.match(/^\/\//) &&
      !decoded.match(/^.+:.+/)
    );
  }

  return false;
};

export const getBurst = (color: string) => {
  return css`
    background-image: url("
      data:image/svg+xml,%3Csvg
      width='465'
      height='225'
      viewBox='0 0 465 224'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'%3E%3Cpath
      d='M1 223L332.118 2L250.776 207.016L353.662 123.272L336.101 184.082L465 106.069'
      stroke='%23${color.substring(1)}'/%3E%3C/svg%3E
    ");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
  `;
};
