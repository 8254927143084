import { UBI } from '@spotify-internal/ubi-logger-js';
import { createEventSender, Environment } from '@spotify-internal/event-sender';
import { createMrktUBIContextNonAuth } from '@spotify-internal/event-definitions/es5/events/createMrktUBIContextNonAuth';
import { createMrktUBIContext } from '@spotify-internal/event-definitions/es5/events/createMrktUBIContext';
import {
  createClientIdContext,
  createApplicationContext,
  createCorrelationIdContext,
  createUserAgentContext,
} from '@spotify-internal/event-sender/es5/contexts';
import { getCorrelationId } from './getCorrelationId';
import { getSilentAuthToken } from '../../../features/src/auth/accessTokenManager/silentAuth';
import { transport } from '../../../features/src/transport';

const context = [
  createApplicationContext({ version: '0.0.0' }),
  createClientIdContext('6cf79a93be894c2086b8cbf737e0796b'),
  createUserAgentContext(globalThis.navigator?.userAgent ?? 'unknown'),
  createCorrelationIdContext(getCorrelationId()),
];

let eventSender = createEventSender<
  Environment.BROWSER | Environment.BROWSER_NON_AUTH
>({
  context,
  transport,
  ownerProvider: () => null,
});

export async function initTransport() {
  if (typeof window !== 'undefined') {
    await transport.connect();
    const [token] = await getSilentAuthToken();
    // only authenticate if logged in
    if (token) {
      await transport.authenticate();
    }

    eventSender = createEventSender<
      Environment.BROWSER | Environment.BROWSER_NON_AUTH
    >({
      context,
      transport,
      ownerProvider: () => null,
    });
  }
}

export const ubiLogger = UBI.getUBILogger({
  eventSender: {
    send(event) {
      const isAuth = transport.isAuthenticated();
      const authorize = { authorize: isAuth };
      eventSender.send(event, authorize);

      if (event.data.page_instance_id) {
        const mrktUBIContext = isAuth
          ? createMrktUBIContext({ ubi_event_id: event.data.page_instance_id })
          : createMrktUBIContextNonAuth({
              ubi_event_id: event.data.page_instance_id,
              page_url: window.location.href,
              title: document.title,
            });

        eventSender.send(mrktUBIContext, authorize);
      }
    },
  },
  authenticationProvider: {
    isAuthenticated: () => transport.isAuthenticated(),
  },
  requiresPersistingState: true,
});
