import React, {
  useState,
  useEffect,
  createContext,
  ReactNode,
  useContext,
} from 'react';
import { defaultFlags } from './defaultFlags';

const useLocalFeatureFlags = () => {
  const [storedFlags, setStoredFlags] = useState(() => {
    try {
      const flags = window.localStorage.getItem('feature-flags');
      return flags ? JSON.parse(flags) : defaultFlags;
    } catch (error) {
      return defaultFlags;
    }
  });

  useEffect(() => {
    window.localStorage.setItem('feature-flags', JSON.stringify(storedFlags));
  }, [storedFlags]);

  return [storedFlags, setStoredFlags];
};

export const useFeatureFlags = () => {
  return useContext(FeatureFlagsContext);
};

// for non functional components
export const getFeatureFlags = () => {
  try {
    const flags = window.localStorage.getItem('feature-flags');
    return flags ? JSON.parse(flags) : defaultFlags;
  } catch (error) {
    return defaultFlags;
  }
};

export const FeatureFlagsContext = createContext<{
  storedFlags: { [key: string]: boolean } | null;
  setStoredFlags: ((flags: { [key: string]: boolean }) => void) | null;
}>({
  storedFlags: null,
  setStoredFlags: null,
});

export const FeatureFlagsProvider = ({ children }: { children: ReactNode }) => {
  const [storedFlags, setStoredFlags] = useLocalFeatureFlags();
  return (
    <FeatureFlagsContext.Provider
      value={{
        storedFlags,
        setStoredFlags,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};
