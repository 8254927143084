import { useEffect } from 'react';

export const useHideMenu = () => {
  const location = window.location;
  const searchParams = new URLSearchParams(location.search);
  const hideQueryParam = searchParams.get('hide_feature_flags_menu');

  useEffect(() => {
    // default to not hidden
    if (hideQueryParam !== localStorage.getItem('hide_feature_flags_menu')) {
      localStorage.setItem(
        'hide_feature_flags_menu',
        hideQueryParam || 'false',
      );
    }
  }, [hideQueryParam]);

  // hide only if explicitly set in url
  const hide = localStorage.getItem('hide_feature_flags_menu') === 'true';

  return hide;
};
