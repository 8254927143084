import React from 'react';
import { useLayoutContext } from '../LayoutState';
import { NavBar as MastheadNav } from '@spotify-internal/s4x-masthead';
import { creatorLightTheme } from '@spotify-internal/encore-web';
import styled from 'styled-components';

const StyleOverride = styled.div`
  svg {
    box-sizing: content-box;
  }
`;

export const MastheadHeader: React.FC<{}> = ({}) => {
  const layoutContext = useLayoutContext();
  const { navBarBackground, navBarTextColor } = layoutContext;

  // used for override on legacyPage like /blog or /video to
  // force dark background and light text
  const { invertedDark } = creatorLightTheme;
  return (
    <StyleOverride className="masthead">
      <MastheadNav
        colors={{
          background: navBarBackground || invertedDark.background.base,
          text: navBarTextColor || invertedDark.text.base,
        }}
      />
    </StyleOverride>
  );
};
