import { FontPreload } from '@spotify-internal/encore-web';
import React from 'react';

export function Fonts() {
  return (
    <>
      {/** always load all fonts */}
      <FontPreload language="latin" weights={['regular', 'bold']} />
      <FontPreload language="arabic" weights={['regular', 'bold']} />
      <FontPreload language="hebrew" weights={['regular', 'bold']} />
      <FontPreload language="cyrillic" weights={['regular', 'bold']} />
    </>
  );
}
