// ignore-string-externalization

import React from 'react';
import storage from 'local-storage-fallback';
import { useRouter } from 'next/router';
import { useCurrentUser } from '../../@mrkt/useCurrentUser';
import { setInitialDimensions } from './ga';
import { createFrodorPageView } from '@spotify-internal/event-definitions/es5/events/createFrodorPageView';
import { createFrodorPageViewNonAuth } from '@spotify-internal/event-definitions/es5/events/createFrodorPageViewNonAuth';
import {
  logGabitoEvent,
  logNonAuthGabitoEvent,
  GabitoEvent,
} from '../../../features/src/gabito';

export const Track: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const lastPathname = React.useRef<string | null>(null);
  const pathname = router.asPath;
  const user = useCurrentUser();
  React.useEffect(() => {
    const href = process.browser ? window.location.href : pathname;
    let gabitoEvent = {} as GabitoEvent;
    let artistId;

    // see if we can grab the last visited artist ID from the backdoor
    if (user?.username) {
      artistId = storage.getItem(
        `${user.username}:analytics:lastVisitedArtist`,
      );
    }
    const partnerId = user?.partnerIds?.google;
    setInitialDimensions({
      userId: partnerId,
      dimension1: 'external',
      dimension2: 'isNotFive',
      dimension3: user?.hasAccess ? 'Yes' : 'No',
      ...(artistId && { dimension4: artistId }),
    });

    if (user) {
      gabitoEvent = createFrodorPageView({
        url: href,
        pathname,
        title: document.title,
        partner_id: partnerId,
        is_employee: null,
        has_access: user.hasAccess,
        artist_id: artistId,
      });
    } else if (user === null) {
      gabitoEvent = createFrodorPageViewNonAuth({
        url: href,
        pathname,
        title: document.title,
      });
    }

    if (user !== undefined) {
      if (pathname !== lastPathname.current) {
        lastPathname.current = pathname;

        // fire gabito event
        if (user !== null) {
          logGabitoEvent(gabitoEvent);
        } else {
          logNonAuthGabitoEvent(gabitoEvent);
        }
      }
    }
  }, [pathname, user]);

  return <>{children}</>;
};
