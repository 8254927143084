import React from 'react';
import Link from 'next/link';
import { useTracking } from '../../features/Tracking';
import { fixHref } from './utils';
import { InteractiveElementType } from '@spotify-internal/frodor-modules';

type InteractionProps = {
  trackingLabel: string;
  trackingCategory: string;
  onClick?: () => {};
  onTouch?: () => {};
  onKeyDown?: (e: any) => {};
};
export const useInteraction = ({
  trackingLabel,
  trackingCategory,
  ...rest
}: InteractionProps) => {
  const { eventHandlerWithAction } = useTracking();

  const handleInteraction = (interactionType: string, e?: any) => {
    eventHandlerWithAction(trackingCategory, 'click', trackingLabel)();

    const defaultKeyDown = () => {
      if (e.key === 'Enter') rest?.onClick?.();
    };
    switch (interactionType) {
      case 'click':
        rest?.onClick && rest?.onClick();
        break;
      case 'touch':
        rest?.onTouch ? rest?.onTouch() : rest?.onClick?.();
        break;
      case 'keyDown':
        rest?.onKeyDown ? rest?.onKeyDown(e) : defaultKeyDown();
        break;
    }
  };

  return {
    onClick: () => handleInteraction('click'),
    onTouchStart: () => handleInteraction('touch'),
    onKeyDown: (e: any) => handleInteraction('keyDown', e),
  };
};

// customComponent element MUST support a component prop
// to allow for passthrough of the Link
// encore interactive elements already do
export const InteractiveElement: InteractiveElementType = ({
  customComponent,
  href,
  children,
  trackingCategory,
  trackingLabel,
  ...rest
}) => {
  const interactionProps = useInteraction({
    trackingCategory,
    trackingLabel,
    ...rest,
  });

  if (customComponent) {
    const Component = customComponent;
    return (
      <Component
        component={href && Link}
        href={fixHref(href)}
        {...rest}
        {...interactionProps}
      >
        {children}
      </Component>
    );
  } else if (href) {
    return (
      <Link href={fixHref(href)!} {...rest} {...interactionProps}>
        {children}
      </Link>
    );
  }
  return (
    <button {...rest} {...interactionProps}>
      {children}
    </button>
  );
};
