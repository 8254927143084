import React from 'react';

export const PreviewLink: React.FC = () => {
  if (!process.browser) {
    return <></>;
  }

  const url = window.location.pathname ?? '/';
  const href = `/api/v1/preview?secret=${process.env.REACT_APP_PREVIEW_SECRET}&url=${url}`;

  return (
    <div style={{ textAlign: 'center', width: '100%', padding: 20 }}>
      <a style={{ color: 'white' }} href={href}>
        Shareable preview link
      </a>
    </div>
  );
};
