// ignore-string-externalization

import React from 'react';
import { useBannerState } from './BannerState';
import { Banner } from './Banner';

export const Banners = () => {
  const [state] = useBannerState();

  return (
    <>
      {state.banners.map(singleBannerState => (
        <Banner
          key={singleBannerState.id}
          singleBannerState={singleBannerState}
        />
      ))}
    </>
  );
};
