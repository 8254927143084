// ignore-string-externalization

import React from 'react';
import {
  Banner as EncoreBanner,
  screenXsMax,
  screenSmMin,
  screenMdMin,
  cssSpacing,
} from '@spotify-internal/encore-web';
import styled, { css } from 'styled-components';
import { SingleBannerState, BannerType } from './BannerState';
import { useBannerActions } from './useBannerActions';

const appMaxWidth = '100vw';

const BannerTypeToColorSet: {
  [key: number]:
    | 'warning'
    | 'announcement'
    | 'negative'
    | 'positive'
    | undefined;
} = {
  [BannerType.WARNING]: 'warning',
  [BannerType.ANNOUNCEMENT]: 'announcement',
  [BannerType.ERROR]: 'negative',
  [BannerType.SUCCESS]: 'positive',
  [BannerType.INFO]: undefined,
};

const BannerWrapper = styled.div<{ compact: boolean }>`
  /* NOTE: modified to be absolute position, since frodor currently relies on NOT pushing
     down the content underneath */
  position: absolute;
  z-index: 3;

  @media (max-width: ${screenXsMax}) {
    top: 42px;
    height: 90px;
  }

  @media (min-width: ${screenSmMin}) {
    top: 66px;
    height: 110px;
  }

  @media (min-width: ${screenMdMin}) {
    top: 76px;
    height: 110px;
  }

  @media (min-width: ${screenSmMin}) {
    ${props =>
      props.compact
        ? css`
            margin: 0;
            padding: 0;
          `
        : css`
            margin-bottom: ${cssSpacing('looser')};
            margin-left: auto;
            margin-right: auto;
            padding-left: ${cssSpacing('looser-3')};
            padding-right: ${cssSpacing('looser-3')};
          `};
    max-width: ${appMaxWidth};
    width: 100%;

    & > * {
      border-radius: ${props => (props.compact ? '0' : '4px')};
    }
  }
`;

export const Banner = ({
  singleBannerState,
}: {
  singleBannerState: SingleBannerState;
}) => {
  const { hide } = useBannerActions();

  const onHide = (bannerId: string) => {
    const handled = singleBannerState.options.onHide?.();
    if (!handled) {
      hide(bannerId);
    }
  };

  return (
    <BannerWrapper compact={singleBannerState.options.compact}>
      <EncoreBanner
        data-testid={`banner-${singleBannerState.id}`}
        colorSet={BannerTypeToColorSet[singleBannerState.type]}
        onClose={
          singleBannerState.options.showDismissButton
            ? () => onHide(singleBannerState.id)
            : undefined
        }
      >
        {singleBannerState.message}
      </EncoreBanner>
    </BannerWrapper>
  );
};
