import { defineBool } from '@spotify-internal/remote-config-properties';

export const remoteConfigLoadedRef = defineBool({
  name: 'remote-config-loaded',
  description:
    'Use to prevent showing a UI or triggering a side effect until after remote config is loaded.',
  default: false,
});

export const livePersonRolloutRef = defineBool({
  name: 'live-person-roll-out',
  description:
    'Feature flag to rollout submission of CS requests to Live Person',
  default: false,
});

export const livePersonErrorMessageRef = defineBool({
  name: 'live-person-error-message',
  description:
    'Feature flag to rollout an error message when Live Person is down',
  default: false,
});

export const caseManagementSDKRef = defineBool({
  name: 'case-management-sdk',
  description:
    'Feature flag to rollout the use of Case Management SDK for Creator Support Chat',
  default: false,
});

export const caseManagementSDKSpTRef = defineBool({
  name: 'case-management-sdk-sp_t',
  description:
    'Feature flag to rollout the use of Case Management SDK for Creator Support Chat for auth and un-auth users',
  default: false,
});

export const FeatureFlagUsersBool = defineBool({
  name: 'frodor-feature-flag-users',
  description: 'Frodor feature flag users',
  default: false,
});
