// ignore-string-externalization

import React from 'react';
import memoize from 'lodash/memoize';
import { createLoader } from '@spotify-internal/creator-data-loading';
import { webgateFetch, WEBGATE_DOMAIN } from './features/webgate-fetch';

export const endpoint = `${WEBGATE_DOMAIN}/s4x-me/v0/me`;

export const currentUserLoader = createLoader(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (_key: 0) => {
    try {
      const response = await webgateFetch(endpoint);
      if (response.status === 401) return null;
      if (!response.ok) throw new Error(`${response.status} ${response.url}`);
      const data = (await response.json()) as Data;
      const formatted = { email: data.loginEmail, ...data } as const;
      // Logger.logSuccess(eventName);
      return formatted;
    } catch (error) {
      // Logger.logError(eventName, error);
      /* eslint-disable-next-line */
      console.error(error);
      // throw error;
      return null;
    }
  },
  {
    cacheKeyFn: () => 0,
  },
);

export const fetchCurrentUser = memoize(async () => {
  return currentUserLoader.load(0);
});

export const useCurrentUser = () => {
  const [user, setUser] = React.useState<undefined | null | Data>(undefined);

  React.useEffect(() => {
    let isMounted = true;
    fetchCurrentUser().then(u => {
      if (isMounted) setUser(u);
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return user;
};

type Data = {
  name: string;
  username: string;
  loginEmail: string;
  imageUrl: string;
  largeImageUrl: string;
  hasAccess: boolean;
  partnerIds: {
    google: string;
    qualaroo?: string;
    getfeedback?: string;
  };
  needsToAcceptTerms?: boolean;
};
