import { v4 as uuidv4 } from 'uuid';
import localStorage from 'local-storage-fallback';

export function getCorrelationId() {
  // if you change this key, it must be updated in both the frontdoor and logged-in s4a code
  const key = 'S4A_CORRELATION_ID';
  const correlationId = localStorage.getItem(key) ?? uuidv4();
  localStorage.setItem(key, correlationId);
  return correlationId;
}
