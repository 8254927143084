// ignore-string-externalization

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootState } from './root';

const finalCompose = compose;
// (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose; // eslint-disable-line

export const store = (initialState?: $TSFixMe, context?: $TSFixMe) =>
  createStore(
    rootState,
    initialState,
    finalCompose(applyMiddleware(thunk.withExtraArgument(context))),
  );
