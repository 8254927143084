// ignore-string-externalization

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ProgressDots } from '@spotify-internal/encore-web';

const Backdrop = styled.div<{ cover: boolean }>`
  ${props =>
    props.cover &&
    `
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
    background: white;
  `}
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wait: React.FC<any> = ({ cover = false }) => {
  return (
    <ThemeProvider theme={{ loadingIndicator: 'light' }}>
      <Backdrop data-testid="waiting" cover={cover}>
        <ProgressDots />
      </Backdrop>
    </ThemeProvider>
  );
};
