import React from 'react';
import { useRouter } from 'next/router';
import { useBannerActions } from '../../@mrkt/features/Banner';
import { useT } from '@spotify-internal/i18n-core';

export function useRouteBanners() {
  const t = useT();
  const router = useRouter();
  const actions = useBannerActions();
  const { showSuccess, showAnnouncement, hideAll } = actions;

  const onHide = React.useCallback(() => {
    router.push(router.asPath.split('?')[0]);
    return true;
  }, [router]);
  const numberOfDays = 3;

  const thanksRequest = t(
    'FRODOR_843723',
    "Thanks for your request. We’ll get back to you by email within {numDays} days. Don't submit another request until you hear from us.",
    "We'll means we will.  Don't means do not.",
    { numDays: numberOfDays },
  );

  const adminNotified = t(
    'FRODOR_780da7',
    "We've notified your admin. Don't submit another request for this team until you hear back.",
    "We've means we have.  Don't means do not.  Hear back means to receive a response",
  );

  const teamOnSpotify = t(
    'FRODOR_3e8332',
    'Your Spotify Analytics team is now on Spotify for Artists. If you haven’t been invited by email, contact your team admin.',
    "Haven't means have not",
  );

  const thanksInterest = t(
    'FRODOR_typeformBanner',
    "Thank you for your interest! We'll be in touch.",
    "We'll means we will",
  );

  React.useEffect(() => {
    hideAll();
    if (!!router.query._lqa) {
      if (router.query._lqa === 'thanksRequest') showSuccess(thanksRequest);
      if (router.query._lqa === 'adminNotified') showSuccess(adminNotified);
      if (router.query._lqa === 'teamOnSpotify') showSuccess(teamOnSpotify);
      if (router.query._lqa === 'thanksInterest') showSuccess(thanksInterest);
    }
    if (router.query['ra-success'] === 'true') {
      showSuccess(thanksRequest, {
        compact: true,
        onHide,
      });
    } else if (router.query['jr-success'] === 'true') {
      showSuccess(adminNotified, {
        compact: true,
        onHide,
      });
    } else if ('sa_ss' in router.query) {
      showAnnouncement(
        <>
          {teamOnSpotify}{' '}
          <a href="https://artists.spotify.com/blog/S4A-all-together-now">
            {t('FRODOR_b78e4a', 'Learn more', '')}
          </a>
        </>,
        {
          compact: true,
          onHide,
        },
      );
    } else if (
      router.query.ref === 'typeform' ||
      router.query.utm_campaign ===
        '2020q2_creator_single_creator_marqueeformredirect'
    ) {
      showSuccess(thanksInterest, {
        compact: true,
        onHide,
      });
    }
  }, [router, onHide, showAnnouncement, showSuccess]);

  return actions;
}
